import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | CNN Swap',
  defaultTitle: 'CNN Swap',
  description:
    'The most trusted decenteralized exchange platform powered by CNN ecosystem.',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@SwapOceanDex',
    site: '@SwapOceanDex',
  },
  openGraph: {
    title: 'CNN Swap- A next evolution DeFi exchange on BNB Smart Chain (BSC)',
    description:
      'The most trusted decenteralized exchange platform powered by CNN ecosystem.',
    images: [{ url: 'https://i.ibb.co/4Wj3S10/logo.png' }],
  },
}
